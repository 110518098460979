import React, { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Toggle from 'react-toggle';
import { globalBoolHandler, handleGlobalLogout } from '../../utils/globalHandlers';
import { UseContextClues } from '../../context/ContextClues'; 
import { UseUserContext } from '../../context/UserContext';
import { UseToggleContext } from '../../context/ToggleContexts';

export default function NavbarDropdown() {

    const location = useLocation()
    const account = UseUserContext();
    const context = UseContextClues();
    const toggler = UseToggleContext();

    function handlLogout() {
        handleGlobalLogout(account, context, toggler);
    };

    const dropDownLinks = "pl-12 pr-8 py-4 hover:bg-hover-blue hover:text-white";

    return (
        <>
            <div className='absolute top-20 right-10 z-[100] bg-white shadow-xl rounded-bl-xl font-medium' >
                <ul className='flex flex-col'>
                    <div className='pl-12 pr-8 py-4 bg-primary-dark text-white w-[300px]'><span className=''>{account.user.userData.full_name}</span></div>
                    <div className='pl-12 pr-8 py-4 bg-primary-dark text-white capitalize'><span className=''>{account.user.userData.user_type}</span></div>
                    <div className='pl-12 pr-8 py-4 bg-primary-dark text-white capitalize'>
                        <span className=''>User guide </span>
                        <Toggle
                            className='float-right text-primary-red'
                            id='helpwindow-toggle'
                            defaultChecked={toggler?.helpWindow}
                            onChange={(e) => globalBoolHandler('helpWindow', toggler.toggle, toggler.setToggle, !toggler.toggle?.helpWindow )} 
                        />
                    </div>
                    {account.user.userData.user_type==="client_mgr"&&<Link to={"/case-manager"} className={dropDownLinks}>Case Management</Link>} 
                    {account.user.userData.user_type==="client_mgr"&&<Link to={"/vendor-console"} className={dropDownLinks}>Vendor Console</Link>} 
                    {account.user.userData.user_type==="client_mgr"&&location.pathname==="/"&&<button onClick={() => globalBoolHandler("orgSettings", toggler.toggle, toggler.setToggle, !toggler.toggle.orgSettings)} className={dropDownLinks+" text-left"}>Org Settings</button>} 
                    {account.user.userData.user_type==="superadmin"&&<Link to={"/signup"} className={dropDownLinks}>(temporary) Add a User</Link>}
                    {account.user.userData.user_type==="superadmin"&&<Link to={"/user-logs"} className={dropDownLinks}>(temporary) User logs</Link>}
                    {account.user.userData.user_type==="client_mgr"&&<Link to={"/revenue-cycle-management"} className={dropDownLinks}>Revenue Cycle Management</Link>}
                    <Link to={"/contact"} className={dropDownLinks}>Contact Support</Link>
                    {account.user.userData.user_type==="client_mgr"&&location.pathname==="/"&&<button onClick={() => globalBoolHandler('tableManagement', toggler.toggle, toggler.setToggle, true )} className={dropDownLinks+" flex"}>Table Management</button>}
                    {location.pathname==="/"&&<button onClick={() => globalBoolHandler('myFiles', toggler.toggle, toggler.setToggle, true )} className={dropDownLinks+" flex"}>My Files</button>}
                    <button aria-label='log out current user' className={dropDownLinks+' flex border-t rounded-bl-xl'} onClick={handlLogout}>
                        Logout 
                    </button>
                </ul>
            </div>
        </>
    )
};