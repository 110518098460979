import { globalUpdateKeyValue } from "./globalHandlers";
import { restructureData, transformData } from "./resultsSummary";

export function transform(data) {
    let obj = {}
    
    if (data) {
        Object.keys(data).map(year => (
            Object.keys(Object.entries(data)[(year.split("Year").join(""))-1][1]).map(channel => {
                obj = {
                    ...obj,
                    [channel]: {
                        ...obj[channel],
                        [year]: Object.entries(data)[(year.split("Year").join(""))-1][1][channel]
                    } 
                }
            })
        ));
    };
    return obj;
};

export function rowTotal(switchContext, context){
    let obj = {
        Year1: 0,
        Year2: 0,
        Year3: 0,
        Year4: 0,
        Year5: 0,
    };

    // if results exists
    if (context?.results) {
        // what is the original results
        let original
        if (switchContext!=="summary"&&switchContext!=="performance") {
            original = restructureData(transformData(JSON.parse(context[switchContext]['Rebates_summary'])))
            // loo the current pricelens
            Object.keys(context.pricingLens[switchContext]?.priceLens?.price).map(year => {
                // according to year
                {Object.keys(context.pricingLens[switchContext]?.priceLens?.price[year]).map(channel => {
                    // according to channel, if hedge check is true
                    if (context.pricingLens[switchContext]?.hedgeCheck) {
                        // if the number exists in the table
                        if (context.pricingLens[switchContext]?.percentageByYear) {
                            if ((original?.[channel]?.[year]*context.pricingLens[switchContext]?.passBackbyYear[year]*(1-context.pricingLens[switchContext]?.priceLens?.price[year][channel]))){
                                // add to the object table
                                obj[year] += (original?.[channel]?.[year]*context.pricingLens[switchContext]?.passBackbyYear[year]*(1-context.pricingLens[switchContext]?.priceLens?.price[year][channel]))
                            };
                        } else {
                            if ((original?.[channel]?.[year]*context.pricingLens[switchContext]?.priceLens?.passBack*(1-context.pricingLens[switchContext]?.priceLens?.price[year][channel]))){
                                // add to the object table
                                obj[year] += (original?.[channel]?.[year]*context.pricingLens[switchContext]?.priceLens?.passBack*(1-context.pricingLens[switchContext]?.priceLens?.price[year][channel]))
                            };
                        };
                    } else {
                        if (context.pricingLens[switchContext]?.percentageByYear) {
                            if ((original?.[channel]?.[year]*context.pricingLens[switchContext]?.passBackbyYear[year]*(1-context.pricingLens[switchContext]?.priceLens?.blanket))){
                                obj[year] += (original?.[channel]?.[year]*context.pricingLens[switchContext]?.passBackbyYear[year]*(1-context.pricingLens[switchContext]?.priceLens?.blanket))
                            };
                        } else {
                            if ((original?.[channel]?.[year]*context.pricingLens[switchContext]?.priceLens?.passBack*(1-context.pricingLens[switchContext]?.priceLens?.blanket))){
                                obj[year] += (original?.[channel]?.[year]*context.pricingLens[switchContext]?.priceLens?.passBack*(1-context.pricingLens[switchContext]?.priceLens?.blanket))
                            };
                        };
                    };
                })};
            });
            globalUpdateKeyValue('totals', obj, context.setPricingLens, switchContext);
        }
    };

    // setTotals(obj)
    return;
};

export function handlePricingLensChange(year, channel, newPrice, name, switchContext, context) {
    if (name) {

        // setPrice();
        globalUpdateKeyValue('priceLens', {
            ...context.pricingLens[switchContext]?.priceLens,
            [name]: newPrice
        }, context.setPricingLens, switchContext);
    } else {
        if (channel === "all") {
            globalUpdateKeyValue('priceLens', {
                ...context.pricingLens[switchContext]?.priceLens,
                "price": {
                    ...context.pricingLens[switchContext]?.priceLens?.price,
                    [year]: {
                        "all": newPrice,
                        "R30": newPrice,
                        "R90": newPrice,
                        "M": newPrice,
                        "S": newPrice,
                    } 
                }
            }, context.setPricingLens, switchContext);
        } else {
            globalUpdateKeyValue('priceLens', {
                ...context.pricingLens[switchContext]?.priceLens,
                "price": {
                    ...context.pricingLens[switchContext]?.priceLens?.price,
                    [year]: {
                        ...context.pricingLens[switchContext]?.priceLens?.price[year],
                        [channel]: newPrice
                    } 
                }
            }, context.setPricingLens, switchContext);
        };
    };
};

export function sumTotalObjects(one, two) {

    let temp = {...one};

    Object.keys(one).map(key => (
        Object.entries(one[key]).map(ele => {
            temp[key][ele[0]] = (two[key][ele[0]])*ele[1];
        })
    ));
    
    return temp;
};

export function deltaArray(one, two) {
    let arr = [];
    if (one.length === two.length) {
        for (let i = 0; i < one.length; i++) {
            arr[i] =  two[i] - one[i];
        };
    };
    return arr
};

export function divideDeltaArray(one, two){
    let temp = [];
    if (one.length == two.length) {
        for (let i = 0; i < one.length; i++) {
            temp[i] = one[i]/two[i];
        };
    }
    return temp;
};

export function bothTotals(switchWithContent, context){
    let obj = {
        Year1: 0,
        Year2: 0,
        Year3: 0,
        Year4: 0,
        Year5: 0,
    };

    // if results exists
    // if (context?.results) {
        // what is the original results
        const original = restructureData(transformData(JSON.parse(context[switchWithContent]?.['Rebates_summary'])))
        if (context.pricingLens[switchWithContent]?.priceLens?.price) {
            // loo the current pricelens
            Object.keys(context.pricingLens[switchWithContent]?.priceLens.price).map(year => {
                // according to year
                {Object.keys(context.pricingLens[switchWithContent]?.priceLens.price[year]).map(channel => {
                    // according to channel, if hedge check is true
                    if (context.pricingLens[switchWithContent]?.hedgeCheck) {
                        // if the number exists in the table
                        if (context.pricingLens[switchWithContent]?.percentageByYear) {
                            if ((original?.[channel]?.[year]*context.pricingLens[switchWithContent]?.passBackbyYear[year]*(1-context.pricingLens[switchWithContent]?.priceLens.price[year][channel]))){
                                // add to the object table
                                obj[year] += (original?.[channel]?.[year]*context.pricingLens[switchWithContent]?.passBackbyYear[year]*(1-context.pricingLens[switchWithContent]?.priceLens.price[year][channel]))
                            };
                        } else {
                            if ((original?.[channel]?.[year]*context.pricingLens[switchWithContent]?.priceLens.passBack*(1-context.pricingLens[switchWithContent]?.priceLens.price[year][channel]))){
                                // add to the object table
                                obj[year] += (original?.[channel]?.[year]*context.pricingLens[switchWithContent]?.priceLens.passBack*(1-context.pricingLens[switchWithContent]?.priceLens.price[year][channel]))
                            };
                        };
                    } else {
                        if (context.pricingLens[switchWithContent]?.percentageByYear) {
                            if ((original?.[channel]?.[year]*context.pricingLens[switchWithContent]?.passBackbyYear[year]*(1-context.pricingLens[switchWithContent]?.priceLens.blanket))){
                                obj[year] += (original?.[channel]?.[year]*context.pricingLens[switchWithContent]?.passBackbyYear[year]*(1-context.pricingLens[switchWithContent]?.priceLens.blanket))
                            };
                        } else {
                            if ((original?.[channel]?.[year]*context.pricingLens[switchWithContent]?.priceLens.passBack*(1-context.pricingLens[switchWithContent]?.priceLens.blanket))){
                                obj[year] += (original?.[channel]?.[year]*context.pricingLens[switchWithContent]?.priceLens.passBack*(1-context.pricingLens[switchWithContent]?.priceLens.blanket))
                            };
                        };
                    };
                })};
            });
        }
    // };

    // setTotals(obj)
    return obj;
};

export function addGrossNetValues(gross, net){
    let arr = [];
    for (let year = 0; year < 5; year++) {
        arr[year]= gross[year] - net[year]?.[1]
    }
    return arr;
};