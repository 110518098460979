import React, { useContext, useEffect, useRef, useState } from 'react';
import { callAllAnalytics, exclusionWaterfall, findTotal, getMacroYear1Stats, getTopVolumeCategories } from '../../utils/analytics';
import { UseContextClues } from '../../context/ContextClues';
import { UseToggleContext } from '../../context/ToggleContexts';
import { downloadToPDFGlobal } from '../../utils/download';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartColumn } from "@fortawesome/free-solid-svg-icons";
import AnalyticsDrugsTable from '../Tables/AnalyticsDrugsTable';
import AnalyticsCategoriesTable from '../Tables/AnalyticsCategoriesTable';
import AnalyticsRebatesTable from '../Tables/AnalyticsRebatesTable';
import AnalyticsMfrsTable from '../Tables/AnalyticsMfrsTable';
import AnalyticsRebatableDrugsTable from '../Tables/AnalyticsRebateableDrugsTable';
import WaterfallBar from '../Charts/WaterfallBar';
import LockedMessage from '../Error/LockedMessage';
import RebtatableClaims from '../Tables/RebatableClaimsTable';
import LineBar from '../Charts/LineBar';
import AnalyticsTopVolumeCategoriesTable from '../Tables/AnalyticsTopVolumeCategoriesTable';

export default function Analytics({}){

    const contentRef = useRef(null);

    const context = UseContextClues();
    const toggler = UseToggleContext();

    const [ type, toggleType ] = useState('standard');
    const [ useNewData, addNewData ] = useState({
        drugs: [],
        categories: [],
        waterfall: [],
        macro: null,
        topVolume: []
    });

    const [ viewGraph, toggleGraph ] = useState({
        drugs: false,
        categories: false,
        volume: false,
    });

    useEffect(() => {
        if (context.casenumber&&context.mainData) {
            exclusionWaterfall(type, context.casenumber, addNewData)
        };
    }, [context.target, type]);

    useEffect(() => {
        if (context.target&&context.casenumber&&context.mainData) {
            setTimeout(() => {
                callAllAnalytics(context);
            }, 1000);
        };
    }, [context.target]);

    useEffect(() => {
        if (context.casenumber) {
            getMacroYear1Stats(context.casenumber, addNewData)
        }
    }, [context.target]);

    useEffect(() => {
        if (context.casenumber) {
            getTopVolumeCategories(type, context.casenumber, addNewData)
        }
    }, [context.target]);

    function handleToggle(graph, bool) {
        toggleGraph(toggles => ({
            ...toggles,
            [graph]: bool
        }))
    };

    useEffect(() => {
        addNewData(data => ({
            ...data,
            drugs: findTotal(context.analytics.top20DrugsTable[type])
        }));
    }, [context.analytics.top20DrugsTable[type]]);
    
    useEffect(() => {
        addNewData(data => ({
            ...data,
            categories: findTotal(context.analytics.top20CategoriesTable[type])
        }));
    }, [context.analytics.top20CategoriesTable[type]]);

    const headerTitle = "font-bold text-base mb-2 mt-8";
    const tableStyles = "w-full text-xs";
    const headerStyles = "capitalize font-bold ";
    const hoverStyle = "hover:border-b-4 hover:mb-[-3px] hover:border-b-light-blue";

    return (
        <>
             <div className={`fixed z-[50] right-10 bottom-0 top-[100px] bg-white border pb-8 pl-6 transition-all duration-500 shadow-xl ${ toggler.aux==='analytics' ? " translate-x-0 ":"translate-x-[2000px] "}`}>
                <div className='relative my-4 overflow-y-auto h-full'>
                    {/* <!-- Analytics & Drivers tab contents --> */}
                    <div className='pr-8'>
                        <div className='sticky bg-white right-0 top-0 float-right pt-2 mb-4 w-full flex justify-between border-b-4 border-box z-[50]'>
                            <div className='text-2xl font-medium pr-20'>Analytics & Drivers</div>
                            <div className='flex gap-4'>
                                <button aria-label='download to pdf' onClick={() => downloadToPDFGlobal(contentRef, "analytics", null, null, null, null, null, toggler.setStatus )} className={hoverStyle}>Download</button>
                                <button aria-label='view standard chart readings' onClick={() => toggleType('standard')} className={hoverStyle+` ${type=="standard"&&"border-b-4 mb-[-3px] border-b-light-blue"}`}>Standard</button>
                                <button aria-label="view target chart readings" onClick={() => toggleType('target')} className={hoverStyle+` ${type=="target"&&"border-b-4 mb-[-3px] border-b-light-blue"}`}>Target</button>
                            </div>
                        </div>
                        <div ref={contentRef} className=''>
                            {useNewData.waterfall.length>0?<><div className={''}>
                                <span className='font-bold'>Claim Exclusion Waterfall</span> <LockedMessage/>
                            </div>
                            <div className='flex-col pb-4 pt-2 flex items-center'>
                                <WaterfallBar data={useNewData.waterfall} />
                                <br className=' page-hidden' />
                            </div></>:<div>No data found</div>}
                            {useNewData.macro&&<RebtatableClaims {...{ useNewData }} />}
                            <br className=' page-hidden' />
                            {context.analytics.top20DrugsTable.standard?.length>0&&<><div className={headerTitle}>Top 20 Drugs
                                <span className='pl-3'>
                                    <button aria-label="toggle chart view" type='button' onClick={() => handleToggle("drugs", !viewGraph.drugs)}>
                                        <span className='text-xs font-medium pr-2'>
                                            View {viewGraph.drugs?"Graph":"Data"}:
                                        </span>
                                        <FontAwesomeIcon className={`text-sm hover:text-light-blue `} icon={faChartColumn}/> 
                                        &nbsp;
                                        &nbsp;
                                        <LockedMessage/>
                                    </button>
                                </span>
                            </div>
                            <div className='flex-col'>
                                <AnalyticsDrugsTable {...{tableStyles, viewGraph, headerStyles, useNewData}}/>
                                <br className=' page-hidden' />
                            </div></>}

                            {context.analytics.top20CategoriesTable.standard?.length>0&&<><div className={headerTitle}>Top 20 Categories
                                <span className='pl-3'>
                                    <button aria-label="toggle chart view" type='button' onClick={() => handleToggle("categories", !viewGraph.categories)}>
                                        <span className='text-xs font-medium pr-2'>
                                            View {viewGraph.categories?"Graph":"Data"}:
                                        </span>
                                        <FontAwesomeIcon className={`text-sm hover:text-light-blue `} icon={faChartColumn}/> 
                                        &nbsp;
                                        &nbsp;
                                        <LockedMessage/>
                                    </button>
                                </span>
                            </div>
                            <div className='flex flex-col'>
                                <AnalyticsCategoriesTable  {...{tableStyles, viewGraph, headerStyles, useNewData}}/>
                                <br className=' page-hidden' />
                            </div></>}
                            {context.analytics.highestProductsNoRebatesTable.standard?.length>0&&<>
                                <div className={headerTitle+" flex justify-between"}>Highest Products with No Rebates&nbsp;<LockedMessage/></div>
                                <AnalyticsRebatesTable {...{tableStyles, headerStyles, context, type}}/>
                                <br className=' page-hidden' />
                            </>}
                            {context.analytics.mfrs.standard?.length>0&&<>
                                <div className={headerTitle}>Top 20 Manufacturers&nbsp;<LockedMessage/></div>
                                <AnalyticsMfrsTable  {...{tableStyles, headerStyles, context, type}}/>
                                <br className=' page-hidden' />
                            </>}
                            {useNewData.topVolume.length>0&&<>
                                <div className={headerTitle}>Top Volume Categories
                                    <span className='pl-3'>
                                        <button aria-label="toggle chart view" type='button' onClick={() => handleToggle("volume", !viewGraph.volume)}>
                                            <span className='text-xs font-medium pr-2'>
                                                View {!viewGraph.volume?"Graph":"Data"}:
                                            </span>
                                            <FontAwesomeIcon className={`text-sm hover:text-light-blue `} icon={faChartColumn}/> 
                                            &nbsp;
                                            &nbsp;
                                            <LockedMessage/>
                                        </button>
                                    </span>
                                </div>
                                <div className='flex justify-center'>
                                    {viewGraph.volume?
                                    <LineBar data={useNewData.topVolume} teller={'category'} bar={'total_claims'} customToolTip={true} />:
                                    <AnalyticsTopVolumeCategoriesTable {...{tableStyles, headerStyles, context: useNewData.topVolume, type}}/>}
                                </div>
                            </>}
                        </div>
                        {context.analytics.rebatable.standard.rdl?.length>0&&<>
                            <div className={headerTitle}>Rebatable Drugs&nbsp;<LockedMessage/> <span className='flex float-right text-xs font-light'>*Click text below to copy entire drug list*</span></div>
                            <AnalyticsRebatableDrugsTable {...{tableStyles, headerStyles, context, type}}/>
                        </>}
                    </div>
                </div>
            </div>
        </>
    );
};