import React, { useState } from 'react';
import Select from 'react-select'
import { addRow, matchLobDynamic } from '../../utils/revenueCycleManagement';
import { globalChangeHandler } from '../../utils/globalHandlers';

export default function JoinsPage({pageData, setPageData, fileSelector}) {

    const [formData, setForm] = useState({
        startDate: "",
        endDate: "",
        priceDate: "",
        case_name: "",
    })
    
    const [ fields, setFeilds ] = useState([
        {
            on_claims_column: "",
            on_pmt_column: "",
        },
    ]);
    
    function handleChange(value, column, idx) {
        let arr = [...fields];
        if (arr[idx]) {
            arr[idx][column] = value.value;
        }
        setFeilds(arr);
    };

    function addTableRow(idx) {
        return (
            <tr>
                <td className='w-[50%]'>
                    <Select onChange={e => handleChange(e, "on_claims_column", idx)} required options={pageData.claims_columns.map(ele => ({value: ele, label: ele}))} />
                </td>
                <td className='w-[50%]'>
                    <Select onChange={e => handleChange(e, "on_pmt_column", idx)} required options={pageData.pmt_columns.map(ele => ({value: ele, label: ele}))} />
                </td>
            </tr>
        );
    };

    function removeRow() {
        let arr = [...fields];
        arr.pop();
        setFeilds(arr);
    };

    function handleSubmit(e) {
        e.preventDefault();
        matchLobDynamic(formData, fields, fileSelector);
    };

    const tableHeadStyles = "border px-2 py-1 bg-primary-dark text-white";

    return (
        <div className='px-20 pt-10'>
            <form onSubmit={handleSubmit} >
                <label>Start date
                    <input name='startDate' type='date' onChange={e => globalChangeHandler(e, null, setForm)} required />
                </label>
                <label>End date
                    <input name='endDate' type='date' onChange={e => globalChangeHandler(e, null, setForm)} required />
                </label>
                <label>Price date
                    <input name='priceDate' type='date' onChange={e => globalChangeHandler(e, null, setForm)} required />
                </label>
                <label>Case name
                    <input name='case_name' type='text' onChange={e => globalChangeHandler(e, null, setForm)} required />
                </label>
                <table className='w-full mt-2'>
                            <thead>
                                <tr className='text-center'>
                                    <th className={tableHeadStyles}>Claims column</th>
                                    <th className={tableHeadStyles}>Payment Column</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Object.keys(fields).map((row, idx) => addTableRow(idx))}         
                            </tbody>
                    </table>
                <button>run process</button>     
            </form>   
            <button onClick={() => addRow(setFeilds)}>add row</button>
            <button onClick={removeRow}>remove row</button>
        </div>
    );
};