import React, { useEffect } from 'react';
import { UseToggleContext } from '../../context/ToggleContexts';
import { getPaymentFields, getPaymentFiles } from '../../utils/revenueCycleManagement';
import { globalBoolHandler, globalChangeHandler } from '../../utils/globalHandlers';

export default function OpeningPage({pageData, setPageData, fileSelector, selectFile}) {

    const toggler = UseToggleContext();

    useEffect(() => {
        getPaymentFiles(setPageData);
    }, []);

    useEffect(() => {
        if (fileSelector.pmt_file && fileSelector.claims_file) {
            getPaymentFields(setPageData, fileSelector.pmt_file, fileSelector.claims_file);
        }
    }, [fileSelector.pmt_file, fileSelector.claims_file]);

    const boxStyle = "flex flex-col py-20 justify-center items-center";
    const importButton = " mb-4 py-2 px-10 uppercase bg-light-blue hover:bg-hover-blue shadow rounded-xl text-white font-bold";
    const boxContainer = "border px-16 rounded shadow-md flex justify-center flex-col shadow-xl rounded";
    const selectStyles = "border rounded-lg px-2 py-1";

    return (
        <>
            <div className='bg-background-light h-[100vh] overflow-y-auto'>
                <div className='max-w-[1440px] h-full bg-white mx-auto px-20 text-center overflow-y-auto flex flex-col justify-center items-center'>
                    <div className='text-3xl py-20 pl-10'>
                        Revenue Cycle Mangement
                    </div>
                    <div className='flex justify-between gap-10'>
                        <div className={boxContainer}>
                            <label className={boxStyle}><span className='font-bold pb-2 text-lg'>Invoice / Payment File</span>
                                <select onClick={e => globalChangeHandler(e, null, selectFile)} name='pmt_file' className={selectStyles}>
                                    <option value="">
                                        --- Choose one ---
                                    </option>
                                    {pageData.payment_tables.map((content, idx) => (
                                        <option key={`${idx}: invoice payment - revenue cycle management: ${content}`}>
                                            {content}
                                        </option>
                                    ))}
                                </select>
                            </label>
                            <button type='button' className={importButton} onClick={() => globalBoolHandler("importPayment", toggler.toggle, toggler.setToggle, true)} >import</button>
                        </div>
                        <div className={boxContainer}>
                            <label className={boxStyle}><span className='font-bold pb-2 text-lg'>Submission Claims File</span>
                                <select onClick={e => globalChangeHandler(e, null, selectFile)} name='claims_file' className={selectStyles}>
                                    <option value="">
                                        --- Choose one ---
                                    </option>
                                    {pageData.claims_tables.map((content, idx) => (
                                        <option key={`${idx}: invoice payment - revenue cycle management: ${content}`}>
                                            {content}
                                        </option>
                                    ))}
                                </select>
                            </label>
                            <button type='button' className={importButton} onClick={() => globalBoolHandler("importWindow", toggler.toggle, toggler.setToggle, !toggler.toggle.importWindow)} >import</button>
                        </div>
                        <div className={boxContainer}>
                            <label className={boxStyle}><span className='font-bold pb-2 text-lg'>Formulary Rate File</span>
                                <select onClick={e => globalChangeHandler(e, null, selectFile)} name='formulary_rate_file'  className={selectStyles}>
                                    <option value="">
                                        --- Choose one ---
                                    </option>
                                    {pageData.formulary_rate_files.map((content, idx) => (
                                        <option key={`${idx}: formulary rate - revenue cycle management: ${content}`}>
                                            {content}
                                        </option>
                                    ))}
                                </select>
                            </label>
                            <button type='button' className={importButton} onClick={() => globalBoolHandler("importFormulary", toggler.toggle, toggler.setToggle, true)} >import</button>
                        </div>
                    </div>
                    <div className='h-[200px]'>
                        {(pageData.claims_columns.length>0&&pageData.pmt_columns.length>0)?<button name='page' value={1} onClick={e => globalChangeHandler(e, null, setPageData)} className='py-3 px-44 bg-light-blue hover:bg-hover-blue rounded-xl text-white shadow-2xl mt-20 mb-[200px] '>Next</button>:null}
                    </div>
                </div>
            </div>
        </>
    )
}