import React from 'react';

export default function OpeningPage({data, choose, next, addVendor}) {

    function makeSelection(name) {
        choose(name);
        next(data=> ({
            ...data,
            page: 1
        }));
    };

    return (
        <>
            <div className='h-full p-20'>
                <div className='font-medium text-2xl pb-6 flex  items-center justify-between'>
                    Choose a vendor 
                    <button 
                        onClick={() => addVendor(true)} 
                        className='font-normal px-6 py-1 rounded-full bg-light-blue hover:bg-hover-blue text-white text-sm'>
                            create new
                    </button>
                </div>
                <div className='flex flex-wrap gap-6 justify-start pb-12'>
                    {Object.entries(data).map((vendor, idx) => (
                        <div key={`${idx}: list of vendors ${vendor[0]}`} className='relative border bg-white flex-grow w-[300px] h-[300px] borders pt-6 shadow hover:shadow-2xl rounded'>
                            <div className='text-2xl capitalize pb-6 font-medium px-6' >
                                {vendor[0]}
                            </div>
                            <div className='text-sm px-6'>
                                Formularies used: <span className='font-bold'>{vendor[1].map((formulary, idx) => ( 
                                    <React.Fragment key={`${idx} - ${formulary}`}>
                                        {formulary.formulary_name?formulary.formulary_name:"N/A"}<span className=''>{idx === vendor[1].length-1 ?'.':", "}</span>
                                    </React.Fragment>
                                ))}</span>
                            </div>
                            <div className='absolute bottom-0 w-full px-6 pb-6'>
                                <button 
                                    onClick={() => makeSelection(vendor[0])} 
                                    type='button' 
                                    className=' w-full border-2 text-sm border-light-blue text-light-blue hover:bg-light-blue hover:text-white rounded-full py-1'>
                                        Open
                                </button>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    )
};