import React, { useState } from 'react';
import { UseToggleContext } from '../context/ToggleContexts';
import ImportWIndow from '../components/ImportWindow/ImportClaim';
import ImportFormulary from '../components/ImportWindow/ImportFormulary';
import ImportPayment from '../components/ImportWindow/ImportPayment';

import OpeningPage from '../components/RevenueCycleManagement/OpeningPage';
import JoinsPage from '../components/RevenueCycleManagement/JoinsPage';

export default function RevenueCycleManagement() {

    const toggler = UseToggleContext();

    // this is temp
    const [pageData, setPageData] = useState({
        page: '0', 
        payment_tables: [],
        claims_tables: [],
        formulary_rate_files: [],
        claims_columns: [],
        pmt_columns: [],
    });
    const [ fileSelector, selectFile ] = useState({
        pmt_file: "",
        claims_file: "",
        formulary_rate_file: "",
    });

    return (
        <>
            {pageData.page === '0'? 
                <OpeningPage {...{pageData, setPageData, fileSelector, selectFile}}/>
            :pageData.page === '1'?
                <JoinsPage {...{pageData, setPageData, fileSelector}} />
            :null
            }
            {toggler.toggle?.importWindow&&<ImportWIndow  />} 
            {toggler.toggle?.importFormulary&&<ImportFormulary  />} 
            {toggler.toggle?.importPayment&&<ImportPayment  />} 
        </>
    )
};