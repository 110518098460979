import React, { useContext, useEffect, useState } from 'react';
import { loadList } from '../../utils/loadSave';
import { UseContextClues } from '../../context/ContextClues';
import { UseToggleContext } from '../../context/ToggleContexts';
import { globalBoolHandler } from '../../utils/globalHandlers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import Load from './Load';
import Create from './Create';

export default function LoadSaved() {

    const context = UseContextClues();
    const toggler = UseToggleContext();

    const [ panel, setPanel ] = useState('');
    const [ caseNumber, updateCase ] = useState();

    useEffect(()=> {
        loadList(setPanel);
    }, []);

    const buttonStyles = "border py-3 bg-light-blue text-white font-bold my-2 hover:bg-hover-blue w-full rounded";

    function handleSwitch(one, two) {
        globalBoolHandler(one, toggler.toggle, toggler.setToggle, true, two, false);
    };

    useEffect(() => {
        const handleEsc = (event) => {
            if (event.key === 'Escape') {
                globalBoolHandler("loadSave", toggler.toggle, toggler.setToggle, false, "saveLoad", false)
            }
        };
        window.addEventListener('keydown', handleEsc);
     
        return () => {
           window.removeEventListener('keydown', handleEsc);
        };
    }, [])
    
    return (
        <>
            <div className='absolute top-0 z-[200] h-[100vh] w-[100vw] bg-gray-500 opacity-[0.6]'></div>
            <div className='absolute top-[10%] z-[200] w-full bottom-[10%] overflow-y-auto '>  
                {panel&&<div className='relative mx-auto bg-white opacity-1 overflow-y-auto py-2 rounded-md w-[600px] h-full' >
                    <div className='flex flex-col'>
                        {context.casenumber&&<button className={"absolute right-6 "} onClick={() => globalBoolHandler("loadSave", toggler.toggle, toggler.setToggle, false, "saveLoad", false)}>
                            <FontAwesomeIcon className={`transition-all duration-500 hover:rotate-[180deg]`} icon={faXmark}/> 
                        </button>}

                        <div className='bg-white pt-2 mb-4 px-4 w-full border-b-4 border-box'>
                            <div className='avsolute text-lg font-medium'>Project Panel</div>
                            <div className='mt-3 flex gap-4 text-sm'>
                                <button onClick={() => handleSwitch("saveLoad", "loadSave")} className={` ${toggler.toggle?.saveLoad&&"border-b-4 mb-[-3px] border-b-light-blue"}`}>Create</button>
                                <button onClick={() => handleSwitch("loadSave","saveLoad")} className={` ${toggler.toggle?.loadSave&&"border-b-4 mb-[-3px] border-b-light-blue"}`}>Load</button>
                            </div>
                        </div>

                        <div className='px-6 flex flex-col text-xs absolute left-0 right-0 bottom-0 top-24'>    
                            {toggler.toggle?.saveLoad&&<Create {...{ caseNumber, updateCase, buttonStyles }} />}                    
                            {toggler.toggle?.loadSave&&<Load {...{buttonStyles, updateCase, caseNumber, panel }} />}
                        </div>
                    </div>
                </div>}
            </div>   
        </>
    );
};