import React, { useEffect, useState } from 'react';
import { UseToggleContext } from '../context/ToggleContexts';
import UserDefinedLists from '../components/UserDefinedLists/UserDefinedLists';
import MainPage from '../components/VendorConsole/MainPage';
import OpeningPage from '../components/VendorConsole/OpeningPage';
import { getVendorDeals } from '../utils/vendorConsole';
import VendorNamePopup from '../components/VendorConsole/VendorNamePopup';

export default function VendorConsole() {

    const toggler = UseToggleContext();

    const [ page, next ]  = useState({
        page: 0,
        section: "main"
    });
    const [ data, set ] = useState({});
    const [ newVendor, addVendor ] = useState(false);
    const [ vendorName, choose ] = useState(null);
    
    useEffect(() => {
        getVendorDeals(set);
    }, []);

    return (
        <>
            <div className='bg-background-light p-3' >
                <div style={{ height: toggler.pageHeight-100  }} className=' mx-auto px-2 '>
                    {page.page === 0 ?
                    <OpeningPage {...{ data, choose, next, addVendor }} />
                    :
                    <MainPage {...{page, vendorName, next, data, set }} />}
                </div>
            </div>
            {toggler.toggle?.userDefinedLists?<UserDefinedLists />:null}
            {newVendor?<VendorNamePopup {...{addVendor, next, choose, vendorName, data}} />:null}
        </>
    )  
};