export function handleToggle(event, toggler) {
    const { name, value } = event.currentTarget;
    toggler.setCollapsible(previous => ({
        ...toggler.collapsible,
        [name]: !previous[name]
    }));
};


export function handleHelp(id, toggler) {
    const target = document.getElementById(id)
    target.classList.add('border-4', "border-primary-red");
    
    toggler.setCollapsible({
        ...toggler.collapsible,
        [id]: true
    })
    target.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
    
    setTimeout(() => {
        target.classList.remove('border-4', "border-primary-red");
    }, 3000);
   
};

export function handleAuxHelp(aux, toggler) {
    toggler.switchAux(aux);
    if (aux === 'results') {
        toggler.setStatus(" a. Once you have completed steps 1-4, you are ready to run your model. After selecting “Run Models”, you will see the results load momentarily. Download to excel or copy to clipboard.")
    } else if (aux === 'rates') {
        toggler.setStatus("Add a custom message for the rates section")
    } else if (aux === 'events') {
        toggler.setStatus("Add a custom message for the events section")
    }

    setTimeout(() => {
        toggler.setStatus("")
    }, 10000)
   
};

export function handleGlobalLogout(account, context, toggler){
    sessionStorage.removeItem('259rx_data')
    account.setUser({
        loggedIn: false,
        token: null,
        userData: {}
    });
	context.setCase(null);
	handleGlobalClear(context, toggler);
};

export function handleGlobalClear(context, toggler, newProject){

    if (newProject) {
        context.setSettings({
            locked: false,
            savedCase: true,
            modelSettings: {
                ...newProject.modelSettings
            },
            formulary: {
              baseFormulary: "",
              targetFormulary: "",
            },
            claims: {
              tName: "",
              startDate: "",
              endDate: ""
            },
            // assumptions: [],
            year:{
              year1: newProject.year.year1
            },
            crystal: {
                CrystalBallContractYear: "cy1",
                CrystalBallFormulary: "base",
                category: "",
            }
        });
        context.setExclusionSettings({
            brandDefinition: "mnoBt",
            otcDefinition: "medispanOpLessDiabeticSupplies",
            exclusions: {
                exclusionLdd: false,
                exclusionAuthGenerics: false,
                exclusionVaccines: true,
                exclusionVa: true,
                exclusionItu: true,
                exclusionLtc: true,
                exclusionHomeInfusion: true,
                exclusionOTC: true,
                exclusion340b: true,
                exclusionCosmetic: false,
                exclusionRepack: false,
                exclusionInstitutional: false,
                exclusionMCO: false,
                exclusionClinic: false,
                exclusionNuclear: false,
                exclusionMilitary: false,
                          
                exclusionCovidDrugs: false,
                exclusionDAW3456: false,
                exclusionCompounds: false,
                exclusionVitamins: false,
                exclusionNonFdaDrugs: false,
                exclusionMSB: false,
            },
            carriers: ["All"],
            events: [],
            compound: [],
            assumptions: [],
            year1: newProject.year.year1,
            estimateLife: newProject.estimateLife,
            dealLength: newProject.dealLength,
            startDate: "",
            priceDate: "",
            custom_lists: {
                exclusion: [],
                constraint: []
            },
        });
    } else {
        context.setSettings({
            locked: false,
            savedCase: true,
            modelSettings: {
              channelDesignation: null,
              daysBreak: null,
              specialtyList: null,
              lDDList: null,
            },
            formulary: {
              baseFormulary: "",
              targetFormulary: "",
            },
            claims: {
              tName: "",
              startDate: "",
              endDate: ""
            },
            // assumptions: [],
            year:{
              year1: ""
            },
            crystal: {
                CrystalBallContractYear: "cy1",
                CrystalBallFormulary: "base",
                category: "",
            }
        });
        context.setExclusionSettings({
            brandDefinition: "mnoBt",
            otcDefinition: "medispanOpLessDiabeticSupplies",
            exclusions: {
                exclusionLdd: false,
                exclusionAuthGenerics: false,
                exclusionVaccines: true,
                exclusionVa: true,
                exclusionItu: true,
                exclusionLtc: true,
                exclusionHomeInfusion: true,
                exclusionOTC: true,
                exclusion340b: true,
                exclusionCosmetic: false,
                exclusionRepack: false,
                exclusionInstitutional: false,
                exclusionMCO: false,
                exclusionClinic: false,
                exclusionNuclear: false,
                exclusionMilitary: false,
    
                            
                exclusionCovidDrugs: false,
                exclusionDAW3456: false,
                exclusionCompounds: false,
                exclusionVitamins: false,
                exclusionNonFdaDrugs: false,
                exclusionMSB: false,
            },
            carriers: ["All"],
            events: [],
            compound: [],
            assumptions: [],
            year1: "",
            estimateLife: 0,
            dealLength: 5,
            startDate: "",
            priceDate: "",
            custom_lists: {
                exclusion: [],
                constraint: []
            },
        });
    };

    context.setResults(null);
    context.setTarget(null);
    context.setMainData(null);
    context.setCrystal();
    toggler.switchAux('');
    context.setModels({
        modelList: null,
        customList: [],
        carriers: [],
        memo: ""
    })
    // toggler.toggleBreakEven(false);

    toggler.setToggle({
        helpWindow: false,
        breakEven: false,
        mobileSidePanel: true,
        loadSave: true,
        breakEven: false,
        orgSettings: false,
        myFiles: false,
        tableManagement: false,
        exludeHedgeFromMargin: true,
        // channelDesignationPanel: false, 
        // importWindow: false,
        // helpWindow: false,
        // loadSave: false,
        // showTime: false,
        // auxLogin: false,
        importFormulary: false,
        importPayment: false, 
    });
    context.setAnalytics({
        top20DrugsTable : {
            standard: [],
            target: []
          },
          top20CategoriesTable : {
            standard: [],
            target: []
          },
          highestProductsNoRebatesTable : {
            standard: [],
            target: []
          },
          mfrs : {
            standard: [],
            target: []
          },
          rebatable : {
            standard: [],
            target: []
          },
    });


    context.setPricingLens({
       results: {
        hedgeCheck: true,
        percentageByYear: false,
        totals: {},
        priceLens: {
          passBack: .90,
          blanket : .05,
          price: {
              Year1: {
                  all: .05,
                  R30: .05,
                  R90: .05,
                  M: .05,
                  S: .05,
              },
              Year2: {
                  all: .05,
                  R30: .05,
                  R90: .05,
                  M: .05,
                  S: .05,
              },
              Year3: {
                  all: .05,
                  R30: .05,
                  R90: .05,
                  M: .05,
                  S: .05,
              },
              Year4: {
                  all: .05,
                  R30: .05,
                  R90: .05,
                  M: .05,
                  S: .05,
              },
              Year5: {
                  all: .05,
                  R30: .05,
                  R90: .05,
                  M: .05,
                  S: .05,
              }
            }
          },
          passBackbyYear: {
            Year1 : 0.9,
            Year2 : 0.9,
            Year3 : 0.9,
            Year4 : 0.9,
            Year5 : 0.9,
          }
       },
       target: {
        hedgeCheck: true,
        percentageByYear: false,
        totals: {},
        priceLens: {
          passBack: .90,
          blanket : .05,
          price: {
              Year1: {
                  all: .05,
                  R30: .05,
                  R90: .05,
                  M: .05,
                  S: .05,
              },
              Year2: {
                  all: .05,
                  R30: .05,
                  R90: .05,
                  M: .05,
                  S: .05,
              },
              Year3: {
                  all: .05,
                  R30: .05,
                  R90: .05,
                  M: .05,
                  S: .05,
              },
              Year4: {
                  all: .05,
                  R30: .05,
                  R90: .05,
                  M: .05,
                  S: .05,
              },
              Year5: {
                  all: .05,
                  R30: .05,
                  R90: .05,
                  M: .05,
                  S: .05,
              }
            }
          },
          passBackbyYear: {
            Year1 : 0.9,
            Year2 : 0.9,
            Year3 : 0.9,
            Year4 : 0.9,
            Year5 : 0.9,
          }
       },
    })
};

export const handleExclusionChange = (e, context) => {
    const { name, value, checked } = e.target;

    context.setSettings({
        ...context.settings,
        locked: true,
        savedCase: false,
    });

    if(name=="brandDefinition"||name=="otcDefinition"||name=="startDate"||name=="year1"||name=="assumptions") {
        context.setExclusionSettings(data => ({
            ...data,
            [name]: value,
        }));
    } else {
        context.setExclusionSettings(data => ({
            ...data,
            exclusions: {
                ...data.exclusions,
                [name]: checked
            }
        }));
    };
};

export function globalNewProjectSettings (e, current, set) {
    const {name, value} = e.target;

    if (name=="year1") {
        set({
            ...current,
            savedCase: false, 
            year: {
                [name]: value
            },
        })
        
    }  else if (name=="estimateLife"||name=="dealLength") {
        set({
            ...current,
            savedCase: false, 
            modelSettings: {
                ...current.modelSettings,
                [name]: value
            },
        });
    }  else if (name=="channelDesignation"||name==="daysBreak"||name==="modelSpecialtyList"||name==="lDDList") {
        set({
            ...current,
            savedCase: false, 
            modelSettings: {
                ...current.modelSettings,
                [name]: value
            },
        });
    };
};

export function globalChangeHandler(event, array, set) {
    const { name, value } = event.target;

    set(data => ({
        ...data,
        [name]: value
    }));
};
export function globalBoolHandler(name, array, set, bool, dual, boolDual, third, thirdBool) {
    if (dual) {
        set({
            ...array,
            [name]: bool,
            [dual]: boolDual,
            [third?third:""]: thirdBool,
        });
    } else {
        set({
            ...array,
            [name]: bool
        });
    }
};

export function produceDate(useDate){
    let date 
    if (useDate) {
        date = new Date(useDate);
    } else {
        date = new Date();
    };
    return date.toLocaleDateString('en-CA');
};

export function globalUpdateKeyValue(key , value, set, which, container) {
    if (container) {
        set(current => ({
            ...current,
            [container] : {
                ...current[container],
                [which]: {
                    ...current[container][which],
                    [key]: value
                }
            }
        }));
    } else {
        set(current => ({
            ...current,
            [which]: {
                ...current[which],
                [key]: value
            }
        }));
    }
};

// export function globalUpdateKeyValue(key , value, set) {
//     set(current => ({
//         ...current,
//         [key]: value
//     }));
// };

export function convertDateToUTC(date) { 
    return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds()); 
};

export function formatDateOnly(date) { 
    const useDate = new Date(date);
    return `${useDate.getMonth()}%2F${useDate.getDay()}%2F${useDate.getFullYear()}`; 
};

export function handleGlobalToggle(set, name, bool) {
    return     set(toggles => ({
        ...toggles,
        [name]: !bool
    }));
};

export function handleCustomListChange(payload, name, set, setSetting) {

    let list = [];

    payload.forEach(val => {
        list.push(val.value);
    });
    
    if (setSetting) {
        setSetting(data => ({
            ...data,
            locked: true,
        }));
    };

    set(data => ({
        ...data,
        custom_lists: {
            ...data.custom_lists,
            [name]: [
                // ...data.custom_lists[name],
                ...list
            ]
        }
    }));
};